.map--container {
  width: 20%;
  margin-top: 180px;
  margin-left: 300px;
}

.contact--info {
  margin-top: 190px;
}

@media all and (min-width: 768px) and (max-width: 1000px) {
  .contact--hero {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  .contact--info {
    background-color: antiquewhite;
    width: 100%;
    text-align: center;
    margin-top: 100px;
  }
  .map--container {
    margin-top: 50px;
    margin-left: 0;
    width: 700px;
    height: 700px;
    position: fixed;
  }
}

@media all and (max-width: 767px) {
  .contact--hero {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  .contact--info {
    background-color: antiquewhite;
    width: 100%;
    text-align: center;
    margin-top: 100px;
  }
  .map--container {
    margin-top: 50px;
    margin-left: 0px;
    width: 390px;
    height: 390px;
    position: fixed;
  }
  .form {
    margin-left: 75px;
  }
}