body {
  background-color: bisque;
  margin: 0;
  padding: 5px;
}

.logo--main {
  font-size: 35px;
  font-weight: 700;
}

.logo--name {
  font-size: 25px;
  font-weight: 500;
  padding-left: 1px;
}

p {
  font-family: Roboto;
}

header {
  background-color: rgb(179, 79, 7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  box-shadow: inset 1px 1px 10px 3px lightsalmon;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1;
}

header img {
  height: 20px;
}

.header button {
  margin: 0px 10px 0px 10px;
}

.logo {
  margin-left: 50px;
  padding: 0;
  margin-right: 30px;
}

header button {
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgb(179,79,7);
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
}

header button:hover {
  color: white;
  transition: 0.3s;
  box-shadow: inset 1px 1px 10px 3px rgb(201, 87, 6);
}

main {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

section p {
  font-size: 30px;
  font-weight: 500;
}

.floor,.floor2 {
  display: flex;
  margin-top: 60px;
  margin-bottom: 20px;
  background-color: rgb(245, 205, 157);
  border-radius: 12px;
  box-shadow: inset 1px 1px 10px 1px lightsalmon;
  width: 870px;
  height: 386px;
}

.floor div,.floor2 div {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  padding-bottom: 20px;
  border-radius: 10px;
  padding: 0;
}

.floor div:hover {
  box-shadow: inset 1px 1px 10px 1px lightsalmon;
}

.floor p,.floor2 p {
  font-size: 20px;
}

.floor img,.floor2 img {
  width: 200px;
  height: 270px;
}

.floor button,.floor2 button {
  border: none;
  padding: 5px;
  border-radius: 15px;
  background-color: rgb(243, 192, 130);
  cursor: pointer;
}

.floor img,.floor2 img {
  margin-right: 0;
}

u p {
  cursor: pointer;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
}

.video-responsive iframe { 
  height: 100%;
  width: 100%;
  padding: 30px 0 30px 60px;
}

.floor2 img {
  border:none;
  border-radius: 10%;
}
.floor2 div {
  margin-right: 80px;
}
.floor2 img:hover {
  box-shadow: #eea849 1px 1px 1px 1px;
}


@import url(https://fonts.googleapis.com/css?family=Raleway);

h2 {
  vertical-align: center;
  text-align: center;
}

html, body {
  margin: 0;
  height: 100%;
}

* {
  font-family: "Raleway";
  box-sizing: border-box;
}

.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #00BAF0;
  background: linear-gradient(to left, #f46b45, #eea849);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #FFF;
  height: 50px;
  padding: 1em;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu > li {
  margin: 0 1rem;
  overflow: hidden;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 146px;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media (max-width: 767px) {
  .menu-button-container {
    display: flex;
  }
  .menu {
    position: absolute;
    top: 0;
    margin-top: 110px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: white;
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
}

@media all and (min-width: 768px) and (max-width: 1000px) {
  body,main,section {
    width: 100%;
    padding: 10px;
  }
  header {
    justify-content: center;
  }
   .floor,.floor2{
    margin-right: 10px;
    width: 100%;
  }
}

@media all and (max-width: 767px){
  body,main,section,header {
    width: 100%;
    padding: 10px;
  }
  .header {
    justify-content: center;
  }
  .headerButtons {
    flex-direction: column;
  }
  main {
    margin-top: 120px;
    margin-left: 50px;
    margin-right: auto;
  }

  .floor,.floor2{
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .header--buttons {
    display: none;
  }
  li {
    font-weight: bold;
  }
  .floor2 {
    align-items: center;
  }
  .floor2 div {
    margin: 0 0 10px 0;
  }
  .video-responsive iframe {
    padding: 20px 0 0 0;
  }
  .langBtn {
    float: right;
  }
  .logo {
    margin-bottom: 13px;
  }
  .logo--main {
    font-size: 25px;
    font-weight: 700;
  }
  .logo--name {
    font-size: 17px;
    font-weight: 500;
  }
}

@media all and (min-width: 768px) {
  li {
    display: none;
  }
}

.sponsorLink {
  color: rgb(122, 121, 121);
}

.sponsorLink:hover {
  text-decoration: underline;
}

